(function ($) {
	'use strict';

    if (typeof defaultInspirationID !== 'undefined') {
	// ********* Page Load *********
	$(document).ready(async function () {
		handleDataRequest(defaultInspirationID);
	});

	let currentPage = 1;
	let catId = defaultInspirationID;

	async function handleDataRequest(categoryid, pageNumber = 1) {
		handleLoadingHTML();
		let data;

		try {
			data = await dataRequest(categoryid, pageNumber);
		} catch (error) {
			console.error('Error:', error);
		}

		if (data && data.posts.length !== 0) {
			appendMediaWallCards(data.posts, currentPage); // Append new data to existing content
			if (pageNumber >= data.total_pages) {
				$('#view-more-gradient').hide(); // Hide or disable the button
			} else {
				$('#view-more-gradient').show(); // Ensure the button is visible
			}
		} else {
			$('#no-results').show();
		}

		handleMasonryUpdate(); // Update Masonry layout with new items
		handleOverflowArrows();
		handleLoadedHTML();
	}

	async function dataRequest(groupID, pageNumber = 1, postsPerPage = 10) {
		//groupdID = 111 is Featured Posts for test purposes
		try {
			const params = new URLSearchParams({
				action: 'fetch_posts_data',
				term_id: groupID,
				page_number: pageNumber, // Include the page number in the request
				posts_per_page: postsPerPage, // Include the number of posts per page in the request
			});

			const response = await fetch(ajax_object.ajax_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				},
				body: params,
			});

			if (!response.ok) {
				throw new Error('Network response was not ok ' + response.statusText);
			}

			const data = await response.json();
			// console.log('data', data);
			return data;
		} catch (error) {
			console.error('Error:', error);
		}
	}

	$('#view-more').on('click', async (e) => {
		currentPage++; // Increment the current page number
		handleDataRequest(catId, currentPage);
	});

	// ********* Utilities *********
	function stripHtmlTags(input) {
		return input.replace(/<\/?[^>]+(>|$)/g, '');
	}

	function getIframeSrc(htmlString) {
		const regex = /src="([^"]+)"/;
		const match = htmlString.match(regex);

		if (match && match[1]) {
			return match[1];
		} else {
			return 'No src attribute found';
		}
	}

	// **********************
	// ********* UI *********
	// **********************

	// ******** Data ********
	function handleLoadingHTML() {
		// $('#inspiration-content').html(''); 							// Clear cards from DOM before fetching new cards
		$('.inspiration-wall-btn').addClass('disable'); // Disable nav buttons so you can't fetch data in the middle
		$('.inspiration-wall-sub-btn').addClass('disable'); // of fetching data. Causes an error
		// $('#inspiration-content').css('opacity', '0');					// Opacity so that Masonry can run on the images once they are added to container
		$('#no-results').hide(); // Reset no results label
		$('#loading-status').show(); // Show loading icon
		$('.inspiration-sub-wrapper').each(function () {
			$(this).removeClass('overflowing');
		});
	}

	function handleLoadedHTML() {
		// setTimeout(function() {
		// $('#inspiration-content').css('opacity', '1');				//Wait for masonry to add divs to content
		$('#loading-status').hide();
		$('.inspiration-wall-btn').removeClass('disable');
		$('.inspiration-wall-sub-btn').removeClass('disable');
		// }, 1000);
	}

	function handleOverflowArrows() {
		var container = $('.inspiration-wall-categories');
		var isOverflowingTop = container.get(0).scrollWidth > container.get(0).clientWidth;

		if (isOverflowingTop) {
			$('.inspiration_main_wrapper').addClass('overflowing');
		} else {
			$('.inspiration_main_wrapper').removeClass('overflowing');
		}

		$('.inspiration-wall-subcategories').each(function () {
			var subContainer = $(this);
			var isOverflowingBot = subContainer.get(0).scrollWidth > subContainer.get(0).clientWidth;

			if (isOverflowingBot) {
				subContainer.parent('.inspiration-sub-wrapper').addClass('overflowing');
			}
		});
	}

	function handleMasonryUpdate() {
		const grid = document.querySelector('.masonry');
		if (typeof Masonry !== 'undefined' && grid) {
			// Add 50px padding to the entire Masonry container
			if (document.body.classList.contains('page-template-page-home')) {
				// Add 50px padding to the top and bottom of the Masonry container
				if (window.innerWidth <= 767) {
					// Add padding only to the top and bottom for mobile
					grid.style.paddingTop = '35px';
					grid.style.paddingBottom = '35px';
				} else {
					// Add padding to all sides for desktop
					grid.style.padding = '35px';
				}
			}
			else {
				grid.style.paddingTop = '35px';
				grid.style.paddingBottom = '35px';
			}
	 
			const masonry = new Masonry(grid, {
				itemSelector: '.masonry-block',
				gutter: 25,
				columnWidth: '.masonry-block',
			});
		
			imagesLoaded(grid, function () {
				masonry.reloadItems(); // Reload Masonry items to include new elements
				masonry.layout(); // Relayout Masonry to accommodate new items
			});
		}
	}

	// ******** Cards *******
	const createSlidesHTML = (postImages) => {
		return postImages
			.map((dataObject, index) => {
				return `<div class="slide">
					  <img src="${dataObject.idea_image.url}" alt="Slide ${index + 1}">
					</div>`;
			})
			.join('');
	};

	function createAssocProdsHTML(dataArray) {
		const divs = dataArray
			.map((item) => {
				return `
				<div class="product-item-container">
					<div class="product-item">
						<div class="image">
							<img src="${item.img[0].product_color_variation_image.url}" alt="${item.img[0].product_color_variation_image.alt}">
						</div>
						<div class="copy">
							<p>${item.title}</p>
							<a class="button" href="${item.url}">Explore</a>
							<a class="button see_all_retailers" href="${item.url}">See All Retailers</a>
						</div>
					</div>
				</div>
			`;
			})
			.join('');

		return `
			<div class="inspiration-related-products-container"> 
				<p>Explore related products</p>
				<div class="inspiration-related-products">
					${divs}
				</div> 
			</div>
		`;
	}

	function createAssocCatsHTML(dataArray) {
		return dataArray
			.map((item) => {
				return `
				<div class="category-item-container">
					<a href="${siteUrl}/inspiration_categories_group/${item.slug}" class="category-item-link">
						<div data-name="${item.slug}" data-categoryid="${item.term_id}" class="category-item">
							${item.name}
						</div>
					</a>
				</div>
			`;
			})
			.join('');
	}

	function createCardHTML(
		index,
		{
			postTitle,
			postTitleModal,
			postText,
			postMediaType,
			postImage,
			postImages,
			postVideo,
			postLikes,
			postComments,
			postLinkUrl,
			postLinkText,
			associatedProducts,
			associatedCategories,
			postType,
			videoType,
		}
	) {
		const cardColors = ['#9DEDE5', '#FF6542', '#F266BF', '#BEB5FF', '#FFCD57'];
		const cardColor = cardColors[Math.floor(Math.random() * cardColors.length)];
		const associatedProductsDiv =
			associatedProducts && associatedProducts.length > 0 ? createAssocProdsHTML(associatedProducts) : '';
		const associatedCategoriesDiv =
			associatedCategories && associatedCategories.length > 0 ? createAssocCatsHTML(associatedCategories) : '';
		const imgElement = `<img class="inspiration-card-img ${
			postMediaType === 'video' || postMediaType === 'carousel' ? 'hide-in-modal' : ''
		}" src="${postImage}"/>`; //If post is a video or carousel don't setup image
		let videoElement = postVideo
			? `<iframe class="inspiration-video" height="580px" width="100%" src="${postVideo}" allowfullscreen frameborder="0"></iframe>`
			: '';
		if (videoType === 'wordpress') {
			videoElement = `<video class="inspiration-video" height="580px" width="100%" controls><source src="${postVideo}" type="video/mp4"></video>`;
		}

		const carouselElement =
			postMediaType === 'carousel'
				? `<div class="card-carousel card-carousel-${index}">${createSlidesHTML(postImages)}</div>`
				: '';
		const likesDiv = '';
		// const likesDiv = (postLikes && postLikes !== '0') ? `<div class="inspiration-card-likes"><sup class="heart">&#9825;</sup> ${postLikes} likes </div>` : '';	//Commented out unless they want to manually update the likes

		return `
			<div class="masonry-block">
				<div data-card-index="${index}" class="inspiration-card-container slide ${postType}" style="background-color:${cardColor};">

					<div class="inspiration-img-container ${postMediaType}">
						${postMediaType === 'video' ? '<div class="play-icon hide-in-modal"></div>' : ''}
						<div class="hover-overlay hide-in-modal hide-on-wall"></div>
						${imgElement}
						${videoElement}
						${carouselElement}
					</div>

					<div class="hover-categories hide-in-modal hide-on-wall">${associatedCategoriesDiv}</div>
					<div class="inspiration-card-copy">
						<div class="inspiration-card-copy-top">
							<div class="inspiration-source"> <a href="${postLinkUrl}" target="_blank"><i class="icon-${postType}" ></i> ${postLinkText} </a> </div>
							<div data-btn-index="${index}" class="btn-close hide"></div>
						</div>
						<div class="inspiration-card-copy-bottom" style="background-color:${cardColor};">
							${postType === 'social_media' ? '' : `<div class="inspiration-card-header show-on-wall">${postTitle}</div>`}
							<div class="inspiration-card-header hide-on-wall">${postTitleModal}</div>
							<div class="inspiration-card-text">${postText}</div>
							${likesDiv}
							<div class="inspiration-bottom-start-copy">
							${associatedProductsDiv}
							<div class="inspiration-related-categories-container"> 
								<p>Explore related categories</p>
								<div class="inspiration-related-categories">
									${associatedCategoriesDiv}
								</div> 
							</div>
							<div class="inspiration-links">
									${
										postType === 'social_media'
											? `<div><a href="${postLinkUrl}" target="_blank"><i class="view"></i> View post </a></div>`
											: ''
									}
									<div ${
										postType !== 'social_media' ? "style='border-right: none;'" : ''
									} class="share-button"> <a href="${postLinkUrl}" target="_blank"><i class="share"></i> Share </a> </div>
								</div>
						   </div>
							<div class="view-arrow hide-on-wall hide-in-modal">View</div>
						</div>
					</div>
				</div>
			</div>`;
	}

	// ******** Data Adapters *******
	// Functions to handle converting the different data shapes from the various endpoints into objects that will work on the front end.
	const givesquadDataAdapter = function (post) {
		const postTitle = post.acf_fields.influencer_name;
		const postTitleModal = post.acf_fields.influencer_name + ': ' + post.acf_fields.influencer_short_description;
		const postText = post.acf_fields.influencer_long_description;
		const postMediaType = 'video';
		const postImage = post.acf_fields.influencer_carousel_image.url; //Swap this with new image for videos
		const postVideo = getIframeSrc(post.acf_fields.influencer_video);
		// need influencer quote
		const postComments = '';
		const postLikes = '';
		const postLinkUrl = post.acf_fields.instagram_link || '';
		const postLinkText = 'instaxus.com';
		const postType = post.post_type;
		return {
			postTitle,
			postTitleModal,
			postText,
			postMediaType,
			postImage,
			postImages: null,
			postVideo,
			postLikes,
			postComments,
			postLinkUrl,
			postLinkText,
			postType,
		};
	};

	const ideasDataAdapter = function (post) {
		const postTitle = post.acf_fields.idea_title;
		const postTitleModal = post.acf_fields.idea_title;
		const postText = stripHtmlTags(post.acf_fields.idea_description);
		const postMediaType = post.acf_fields.idea_images.length > 1 ? 'carousel' : 'image';
		const postImages = post.acf_fields.idea_images;
		const postImage = post.acf_fields.idea_images[0].idea_image.url;
		const postComments = '';
		const postLikes = '';
		const postLinkUrl = post.link || '';
		const postLinkText = 'instaxus.com';
		const postType = post.post_type;
		return {
			postTitle,
			postTitleModal,
			postText,
			postMediaType,
			postImage,
			postImages,
			postLikes,
			postComments,
			postLinkUrl,
			postLinkText,
			postType,
		};
	};

	const socialMediaDataAdapter = function (post) {
		const postTitle = post.acf_fields.username;
		const postTitleModal = post.acf_fields.username;
		const postText = post.acf_fields.text_plain;
		const postMediaType = post.acf_fields.post_type;
		const postImage =
			post.acf_fields.images && post.acf_fields.images[0] ? post.acf_fields.images[0].image.image_url : '';
		const postVideo = post.acf_fields.video_type && post.acf_fields.video_url ? post.acf_fields.video_url : '';
		const postComments = post.acf_fields.comments;
		const postLikes = post.acf_fields.likes;
		const postLinkUrl = post.acf_fields.source_url;
		const postLinkText = `@${post.acf_fields.username}`;
		const postType = post.post_type;
		const videoType = post.acf_fields.video_type;
		return {
			postTitle,
			postTitleModal,
			postText,
			postMediaType,
			postImage,
			postImages: null,
			postVideo,
			postLikes,
			postComments,
			postLinkUrl,
			postLinkText,
			postType,
			videoType,
		};
	};

	// Functions to handle converting the different data shapes from the various endpoints into objects that will work on the front end.
	const dataConverters = {
		give_squad: givesquadDataAdapter, //Handle posts for givesquad
		ideas: ideasDataAdapter, //Handle posts for ideas
		social_media: socialMediaDataAdapter, //Handle posts for IG
	};

	// ******** Main UI Loop  ********
	// Main loop for converting category data into cards to put to page
	function appendMediaWallCards(data, page) {
		const itemsPerPage = 10; // Number of items per page
		const startIndex = (page - 1) * itemsPerPage; // Calculate the starting index for the current page

		for (const [index, post] of data.entries()) {
			const overallIndex = startIndex + index; // Calculate the overall index
			const dataConverterFunction = dataConverters[post.post_type];
			const convertedPostData = {
				...dataConverterFunction(post),
				associatedProducts: post.associated_products_data,
				associatedCategories: post.acf_fields.associated_categories,
			};
			const cardHTML = createCardHTML(overallIndex, convertedPostData);
			const $cardElement = $(cardHTML); // Convert HTML string to jQuery object
			$('#inspiration-content').append($cardElement); // Append card element to the DOM
			addCardclickEvents($cardElement);
		}
	}

	// ********* Menu Button Events *********
	// Add click events to category nav buttons
	$('.inspiration-wall-sub-btn, .inspiration-wall-btn').on('click', async (e) => {
		resetPage(e);
		handleDataRequest(e.target.dataset.categoryid);
		catId = e.target.dataset.categoryid;

		if ($(e.target).hasClass('inspiration-wall-sub-btn')) {
			const category = $(e.target).data('categories');
			activateWallBtnByCategory(category);
		}
	});

	$('#main-nav-right-arrow').on('click', () => {
		var container = $('.inspiration-wall-categories');
		console.log('container', container);
		var scrollTarget = container.get(0).scrollWidth - container.innerWidth();
		console.log('scrollTarget', scrollTarget);
		container.animate(
			{
				scrollLeft: scrollTarget,
			},
			500
		);
	});
	$('#main-nav-left-arrow').on('click', () => {
		var container = $('.inspiration-wall-categories');
		var scrollTarget = 0;
		container.animate(
			{
				scrollLeft: scrollTarget,
			},
			500
		);
	});
	$('.sub-nav-right-arrow').on('click', (e) => {
		var container = $(`#inspiration-sub-container-${e.target.dataset.index}`);
		var scrollTarget = container.get(0).scrollWidth - container.innerWidth();
		container.animate(
			{
				scrollLeft: scrollTarget,
			},
			500
		);
	});
	$('.sub-nav-left-arrow').on('click', (e) => {
		var container = $(`#inspiration-sub-container-${e.target.dataset.index}`);
		var scrollTarget = 0;
		container.animate(
			{
				scrollLeft: scrollTarget,
			},
			500
		);
	});

	// Scroll main nav
	$('.inspiration-wall-btn').on('click', (e) => {
		var container = $('.inspiration-wall-categories');
		var scrollTarget = $(e.target).position().left;
		container.animate(
			{
				scrollLeft: scrollTarget,
			},
			500
		);
	});

	// Scroll main subnav
	$('.inspiration-wall-sub-btn').on('click', (e) => {
		var container = $(`#inspiration-sub-container-${e.target.dataset.categories}`);
		var scrollTarget = $(e.target).position().left;
		container.animate(
			{
				scrollLeft: scrollTarget,
			},
			500
		);
	});

	$('.inspiration-wall-btn').on('click', (e) => {
		const category = $(e.target).data('categories');
		activateWallBtnByCategory(category);

		// Handle hide / show nav submenu
		$('.inspiration-wall-subcategories').addClass('d-none');
		$(`#inspiration-sub-container-${category}`).removeClass('d-none');
	});

	function activateWallBtnByCategory(category) {
		$('.inspiration-wall-btn').removeClass('active');
		$(`.inspiration-wall-btn[data-categories="${category}"]`).addClass('active');
	}

	function closeModal() {
		$('body').css('overflow', '');
		$('#inspiration-modal-container').addClass('d-none');
		$('#inspiration-slick').slick('unslick');
		$('#inspiration-slick').html('');
	}

	function resetPage(e) {
		currentPage = 1;
		// Reset nav
		$('.inspiration-wall-btn, .inspiration-wall-sub-btn').removeClass('active');
		$(e.target).addClass('active');

		// Clear content
		$('#inspiration-content').html('');
	}

	//Init Slide tracker for slick afterChange event to reset iframe video player so it will stop playing.
	var previousSlideIndex = 0;


	// ********* Modal Click Events *********
	function addCardclickEvents($cardElement) {
		$cardElement.on('click', '.category-item-link', function (e) {
			// Prevent the default behavior of the click event (if needed)
			//e.preventDefault();
	
			// Hide the modal or perform other actions
			$('#inspiration-modal-container').hide();
	
			// Your event handler code goes here
			console.log('Clicked on an element with the class "category-item-link"');
	
			// Additional logic or actions based on the click event
		});
		$cardElement.on('click', (e) => {
			var $modalCard = $(e.currentTarget).children('.inspiration-card-container');
			
			// $('html, body').scrollTop(0);												//Scroll to top of page
			// $('body').css('overflow', 'hidden');
			$('#inspiration-modal-container').removeClass('d-none'); //Show modal

			$('#inspiration-slick').html($('#inspiration-content').html()); //Copy all the data from the wall to the modal div
			$('#inspiration-slick .masonry-block').each(function () {
				//Remove masonry styling in modal div
				$(this).css({ position: '', top: '', left: '', transform: '' });
				$(this).removeClass('masonry-block');
			});

			// Reset the src attribute to reload the iframe and stop the video
			$('#inspiration-slick').on('afterChange', function (event, slick, currentSlide) {
				var previousSlide = $(slick.$slides[previousSlideIndex]);
				var previousIframe = previousSlide.find('iframe');
				var previousVideo = previousSlide.find('video');

				// If it's an iframe, reset the src attribute to stop the video
				if (previousIframe.length) {
					var iframeSrc = previousIframe.attr('src');
					previousIframe.attr('src', '').attr('src', iframeSrc);
				}

				// If it's a video tag, pause the video
				if (previousVideo.length) {
					previousVideo[0].pause();
				}

				// Update the previousSlideIndex for the next change
				previousSlideIndex = currentSlide;
			});

			//Now that all the wall cards are copied into the modal,
			var isClickInsideInnerSlider = false;

		//	if ($(window).width() < 767) {
				$('.inspiration-related-products-container').on({
					click: function () {
						isClickInsideInnerSlider = true;
						updateSwipeOption();
					}
				});
			
				$(document).on('click', function (event) {
					// Check if the click occurred outside .inspiration-related-products-container
					if (!$(event.target).closest('.inspiration-related-products-container').length) {
						isClickInsideInnerSlider = false;
						updateSwipeOption();
						console.log('Click Outside');
					}
				});
			//}

		function updateSwipeOption() {
			// Update the swipe option based on the click position
			$('#inspiration-slick').slick('slickSetOption', 'swipe', !isClickInsideInnerSlider, true);
		}	
				

			// Your existing code for the outer/slick slider initialization
			$('#inspiration-slick').slick({
				dots: false,
				slidesToShow: 1,
				arrows: true,
				slidesToScroll: 1,
				autoplay: false,
				centerMode: false,
				initialSlide: parseInt($modalCard.attr('data-card-index')),
				swipe: true, // Initial swipe option for the outer/slick slider
			});
			previousSlideIndex = parseInt($modalCard.attr('data-card-index')); //Set slide tracker when modal is opened.

			//Once all the wall cards are converted into slides in the modal,
			//some of the cards have an additional slider inside of them.
			//Setup slider for cards that have carousels inside.
			$('#inspiration-modal .card-carousel').each(function () {
				$(this).slick({
					dots: true,
					slidesToShow: 1,
					arrows: true,
					slidesToScroll: 1,
					autoplay: false,
					centerMode: false,
				});
			});

			//Unhide all close buttons on slick slides and setup close button to close modal when clicked
			$('.slick-slide .btn-close').removeClass('hide'); //Unhide close button
			$('.slick-slide .btn-close').on('click', (e) => closeModal()); //Handle click event for closing the modal

			// Setup associated category buttons
			$('.slick-slide .category-item').on('click', () => {
				closeModal();
				resetPage(e);
				handleDataRequest(e.target.dataset.categoryid);
			});

			// social native mobile
			$('.share-button').on('click', function (e) {
				e.preventDefault(); // Prevents the default action of the <a> tag
				const postLinkUrl = $(this).find('a').attr('href');

				if (navigator.share) {
					const EN = {
						title: 'InstaxUS',
						text: '',
						url: postLinkUrl,
					};
					// navigator.share(lang == 'EN' ? EN : SP)
					navigator
						.share(EN)
						.then(() => console.log('Content shared successfully!'))
						.catch((error) => console.log('Error sharing content:', error));
				} else {
					console.log('Web Share API is not supported in your browser.');
					window.open(postLinkUrl, '_blank');
				}
			});

			
		});



		var videoStopped = false; // Flag to track if videos have been stopped

		function pauseAllVideos() {
			$('iframe').each(function (index, iframe) {
				try {
					var videoPlayer = iframe.contentWindow;
					if (videoPlayer && typeof videoPlayer.postMessage === 'function') {
						// Send a postMessage command to pause the video
						videoPlayer.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
					}
				} catch (error) {
					console.error('Error pausing video:', error);
				}
			});
		}

		// Call the function to pause all videos (you can trigger this based on your requirements)
		pauseAllVideos();
	}

	

	console.log('no insp');
} else {
	//console.warn('defaultInspirationID is not defined. Script will not run.');
}
	


})(jQuery);
